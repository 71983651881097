import React from "react"
import tw, { styled } from "twin.macro"

const BasicBackgroundSection = styled.div`
  ${({ image }) => (image ? `background: url(${image});` : "")}
  ${({ bgStack }) => (bgStack ? `background: ${bgStack.join(", ")};` : "")}
  ${({ repeat }) => (repeat ? `background-repeat: ${repeat};` : "")}
  ${({ size }) => (size ? `background-size: ${size};` : "")}
  ${({ bgPosition }) => (bgPosition ? `background-position: ${bgPosition};` : "")}
  ${({ color }) => (color ? `background-color: ${color};` : "")}
  ${({ position }) => (position ? `position: ${position};` : "")}
`

export default BasicBackgroundSection
