import React from "react"
import tw, { styled } from "twin.macro"

import YouTube from "components/youtube.js"
import Tag from "components/tag"

const Title = styled.h3`
  ${tw`font-display text-center m:text-left text-white py-2`}
  font-size: 30px;
  line-height: 35px;
  @media (min-width: 640px) {
    font-size: 45px;
    line-height: 53px;
  }
  @media (min-width: 1024px) {
    font-size: 50px;
    line-height: 56px;
  }
`

const Body = styled.div`
  ${tw`font-body text-white py-2`}
  font-size: 15px;
  line-height: 27px;
`

export default ({ video }) => {
  if (!!video) {
    return (
      <div className="px-2 py-16 flex flex-wrap l:flex-row overflow-hidden mx-auto max-w-s m:max-w-m l:max-w-l">
        <div className="mx-auto max-w-s m:max-w-l l:w-1/2 l:pr-16 flex flex-col text-center items-center m:text-left m:items-start justify-center">
          <Tag className="red">{video.label}</Tag>
          <Title>{video.title}</Title>
          {video.body && (
            <Body dangerouslySetInnerHTML={{ __html: video.body.html }} />
          )}
        </div>
        <div className="w-full l:w-1/2 l:px-2 py-4 l:px-0 overflow-visible flex flex-col justify-center">
          {video.youtubeId && (
            <YouTube
              videoId={video.youtubeId}
              preview={video.imagePreview?.url}
            />
          )}
        </div>
      </div>
    )
  } else {
    return <></>
  }
}
