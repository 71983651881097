import React from "react"

import tw, { styled } from "twin.macro"

const VerticalLine = styled.div`
  ${tw`hidden l:block`}
  background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.27) 5%, rgba(255,255,255,0.27) 95%, rgba(255,255,255,0) 100%);
  position: absolute;
  left: 50%;
  margin-left: -44px;
  top: 0px;
  width: 1px;
  bottom: 1000px;

  &.products {
    left: calc(50% - (100% / 24));
    bottom: 0;
    margin-left: 0;
  }

  &.newsroom {
    ${tw`m:block bottom-0 ml-0`}

    background: linear-gradient(
      180deg,
      rgba(135, 135, 135, 1) 0%,
      rgba(135, 135, 135, 1) 95%,
      rgba(135, 135, 135, 0) 100%
    );

    left: -26px;
  }
`

export default VerticalLine
