import React from "react"
import tw, { styled } from "twin.macro"

import InsightsCard from "components/insights-card"

const Insights = styled.div`
  ${tw`py-20 mx-auto max-w-s m:max-w-m l:max-w-l`}

  &.titleless {
    ${tw`pt-12`}
  }

  &.newsroom {
    ${tw`pt-20 pb-32 m:pt-40 l:pt-44 m:pb-48 l:pb-44`}
  }
`

const InsightsTitle = styled.h2`
  ${tw`py-8 w-full font-display font-bold text-grey-10 text-center`}
  font-size: 30px;
  @media (min-width: 640px) {
    font-size: 45px;
    line-height: 53px;
  }
  @media (min-width: 1024px) {
    font-size: 50px;
    line-height: 56px;
  }

  &.newsroom {
    ${tw`pt-0`}
  }
`

const ArticleShowcase = ({
  articles,
  title = "Required Reading",
  wide = false,
  className = "",
}) => {
  return (
    <Insights className={`${className} ${title === null ? "titleless" : ""}`}>
      {title !== null && (
        <InsightsTitle className={className}>{title}</InsightsTitle>
      )}
      <div className="flex flex-wrap items-center m:items-stretch overflow-hidden m:-mx-2 justify-center">
        {articles.map((post, idx) => (
          <InsightsCard post={post} key={idx} wide={wide} />
        ))}
      </div>
    </Insights>
  )
}

export default ArticleShowcase
