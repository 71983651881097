import React from "react"
import { Link } from "gatsby"
import tw, { styled } from 'twin.macro'

import GreenLine from "images/icons/industry-green-line.inline.svg"
import PurpleLine from "images/icons/industry-purple-line.inline.svg"

const Line = styled.div`
  display: none;
  position: absolute;
  left: 50%;
  margin-left: -3px;

  &.down {
    bottom: -18px;
  }

  &.up {
    top: -18px;
    transform: scaleX(-1) scaleY(-1);
  }

  @media (min-width: 1200px) {
    display: block;
  }
`

const Industry = styled.div`
  ${tw`justify-center rounded-full text-center`}
  height: 148px;
  width: 148px;
  @media (min-width: 640px) {
    height: 185px;
    width: 185px;
  }
`

const CoverContainer = styled.div`
  ${tw`relative`}
  height: ${props => (props.tooTall ? "157px" : "148px")};
  overflow: visible;
  top: ${props => (props.tooTall ? "-9px" : "0")};
  width: 148px;
  @media (min-width: 640px) {
    height: ${props => (props.tooTall ? "196px" : "185px")};
    top: ${props => (props.tooTall ? "-11px" : "0")};
    width: 185px;
  }
`
const LabelContainer = styled.div`
  ${tw`p-2 relative flex flex-col justify-center items-center`}
  height: 148px;
  top: -147px;
  width: 148px;
  @media (min-width: 640px) {
    height: 185px;
    top: -185px;
    width: 185px;
  }
`

const Label = styled.span`
  ${tw`flex text-white font-display align-middle z-10`}
  text-shadow: rgba(0,0,0,0.5) 1px 0 10px;
  font-size: 20px;
  line-height: 26px;
`

const OuterCircle = styled(Link)`
  ${tw`p-1 my-3 mx-4 block rounded-full overflow-visible border-2 relative`}
  border-color: rgba(255,255,255,0);
  transition: all 0.2s;
  &:hover {
    ${tw`border-springgreen-light`}

    div#default {
      opacity: 0;
    }
    div#active {
      opacity: 1;
    }
  }

  div#default {
    opacity: 1;
    transition: all 0.1s;
  }
  div#active {
    opacity: 0;
    transition: all 0.1s;
  }
`

export default ({ cover, label, link, tooTall, point }) => (
  <OuterCircle to={link}>
    <Line id="default" className={point || "down"}>
      <PurpleLine />
    </Line>
    <Line id="active" className={point || "down"}>
      <GreenLine />
    </Line>
    <Industry>
      <CoverContainer tooTall={tooTall}>
        {cover && (
          <img
            src={cover}
            alt=""
            className="absolute flex-none bottom-0"
            style={{ overflow: "visible" }}
          />
        )}
      </CoverContainer>
      <LabelContainer>
        <Label>{label}</Label>
      </LabelContainer>
    </Industry>
  </OuterCircle>
)
