import React from "react"
import tw, { styled } from "twin.macro"

const StyledBlock = styled.div`
  h1 {
    ${tw`text-4xl leading-tight m:text-6xl m:leading-tight font-extrabold text-center`}

    @media (min-width: 640px) {
      line-height: 115%;
    }
  }

  h2 {
    ${tw`text-4xl leading-tight m:text-5xl m:leading-tight font-extrabold text-center`}
  }

  h3 {
    ${tw`text-2xl leading-normal m:text-4xl m:leading-tight font-display text-center`}
  }

  h5 {
    ${tw`text-base leading-tight m:text-lg m:leading-tight font-display text-center`}
  }

  &.smaller {
    h5 {
      ${tw`text-sm leading-tight m:text-lg m:leading-tight`}
    }
  }

  &.page-header {
    p {
      ${tw`text-lg leading-normal m:text-xl m:leading-normal font-body text-center mt-1 m:mt-3`}
    }
  }

  &.secondary-section-header {
    p {
      ${tw`text-lg leading-normal font-body text-center mt-4 m:mt-6`}
    }
  }

  p {
    &.large-subtitle {
      ${tw`text-base m:text-xl leading-normal text-center font-body`}

      line-height: 175%;
    }

    &.medium-subtitle {
      ${tw`text-base m:text-lg leading-normal text-center font-body`}

      line-height: 175%;
    }

    &.card-subtitle {
      ${tw`text-xs m:text-sm text-center font-body`}

      line-height: 175%;
    }
  }
`

export default StyledBlock
