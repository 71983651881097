import React from "react"
import tw, { styled } from "twin.macro"
import { SwiperSlide } from "swiper/react"

import ResponsiveSlider from "components/responsive-slider"

const BrandSlide = styled.div`
  ${tw`flex items-center justify-center`}
  height: 100px;

  img {
    max-width: 170px;
    max-height: 100%;

    &.tablet-full {
      @media (min-width: 640px) {
        max-width: 95%;
      }

      @media (min-width: 1200px) {
        max-width: 170px;
      }
    }
  }

  &.home {
    @media (min-width: 1200px) {
      height: 90px;
    }
  }
`

const Title = styled.h3`
  ${tw`font-display text-center text-yellow`}
  font-size: 17px;
  line-height: 27px;
`

const PartnerLogos = ({ data, dark, tabletFull, home }) => {
  const { logos, heading, headingColor } = data

  return (
    <div className="pb-16">
      <Title style={headingColor && { color: headingColor.hex }}>
        {heading || "Trusted by the smartest minds in global media"}
      </Title>
      <div className="py-4">
        <ResponsiveSlider dark={dark} tabletFull={tabletFull}>
          {logos.map((logo, idx) => (
            <SwiperSlide key={idx}>
              <BrandSlide className={`${home ? "home" : ""}`}>
                <img
                  src={logo.url}
                  alt="partner logo"
                  className={`${tabletFull ? "tablet-full" : ""}`}
                />
              </BrandSlide>
            </SwiperSlide>
          ))}
        </ResponsiveSlider>
      </div>
    </div>
  )
}

export default PartnerLogos
