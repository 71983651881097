import React from "react"
import tw, { styled } from 'twin.macro'

const LightSlant = styled.div`
  ${tw`-mt-16`}
  position: relative;

  &::before {
    ${tw`bg-grey-95`}
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: ${props => (props.height ? props.height : "calc(100% + 100px)")};
    clip-path: polygon(0% 10px, 100% 0%, 100% calc(100% - 10px), 0% 100%);
    @media (min-width: 640px) {
      clip-path: polygon(0% 45px, 100% 0%, 100% calc(100% - 45px), 0% 100%);
    }
  }

  & > div {
    position: relative;
    z-index: 1;
  }
`
const LightSlantedSection = ({ children, ...rest }) => (
  <LightSlant {...rest}>
    <div>{children}</div>
  </LightSlant>
)

export default LightSlantedSection
