import React from "react"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"

import Button from "components/button"
import BasicBackgroundSection from "components/basic-background-section"
import BgUnlock360 from "images/bg/unlock360.jpg"

const LargeTitle = styled.h2`
  ${tw`py-2 w-full font-display font-bold text-white text-center`}
  font-size: 30px;
  line-height: 35px;
  @media (min-width: 640px) {
    font-size: 45px;
    line-height: 53px;
  }
  @media (min-width: 1024px) {
    font-size: 60px;
    line-height: 79px;
  }
`

const Paragraph = styled.p`
  ${tw`font-body text-white`}
  font-size: 13px;
  line-height: 25px;
  @media (min-width: 640px) {
    font-size: 15px;
    line-height: 27px;
  }
`

const Slant = styled.div`
  clip-path: polygon(0% 10px, 100% 0%, 100% 100%, 0% 100%);
  @media (min-width: 640px) {
    clip-path: polygon(0% 45px, 100% 0%, 100% 100%, 0% 100%);
  }
`

const Dark = ({ children }) => (
  <Slant>
    <BasicBackgroundSection
      image={BgUnlock360}
      color="#000000"
      bgPosition="center"
      size="cover"
      repeat="no-repeat"
    >
      <div className="py-32 pt-64 -mt-64">{children}</div>
    </BasicBackgroundSection>
  </Slant>
)

export default ({ children, cta, title, url }) => {
  return (
    <Dark>
      <div className="px-2 pt-20 flex flex-col items-center overflow-hidden mx-auto max-w-s m:max-w-m l:max-w-l">
        <LargeTitle>{title || "Monetize global content demand"}</LargeTitle>
        <Paragraph className="py-4 w-full m:w-3/4 l:w-1/2 text-center">
          {children ||
            "Discover how a partnership with Parrot Analytics will elevate your industry leadership."}
        </Paragraph>

        <div className="py-4">
          <Link to={url || "/solutions/"}>
            <Button className="purple small glow">
              {cta || "Discover solutions"}
            </Button>
          </Link>
        </div>
      </div>
    </Dark>
  )
}
