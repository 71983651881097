import React from "react"
import { Swiper } from "swiper/react"
import { Autoplay, Navigation, Grid } from "swiper/modules"
import styled from "styled-components"

const Container = styled.div`
  .swiper-button-prev,
  .swiper-button-next {
    color: ${(props) => (props.dark ? "#737373" : "#ffffff")};
    height: 24px;
    width: 24px;
    @media (min-width: 1200px) {
      display: none;
    }
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    margin-top: 12px;
    font-size: 1.5rem;
  }
`

const ResponsiveSlider = ({ children, dark, tabletFull = false }) => {
  const fullBreakpoint = {
    loop: false,
    allowTouchMove: false,
    autoplay: false,
    centeredSlides: false,
    spaceBetween: 0,
    slidesPerView: 6,
    slidesPerColumn: 2,
    slidesPerColumnFill: "row",
    grid: {
      rows: 2,
      fill: "row",
    },
  }

  const tabletBreakpoint = {
    centeredSlides: true,
    spaceBetween: 30,
    slidesPerView: 1,
  }

  const params = {
    autoplay: true,
    centeredSlides: true,
    navigation: true,
    slidesPerView: 1,
    spaceBetween: 60,
    watchOverflow: true,
    modules: [Autoplay, Navigation, Grid],
  }

  if (tabletFull) {
    params.breakpoints = {
      640: fullBreakpoint,
    }
  } else {
    params.breakpoints = {
      640: tabletBreakpoint,
      1200: fullBreakpoint,
    }
  }

  return (
    <Container dark={dark}>
      <Swiper {...params}>{children}</Swiper>
    </Container>
  )
}

export default ResponsiveSlider
