import React, { useEffect } from "react"
import tw, { styled, theme } from "twin.macro"
import { motion, useAnimation } from "framer-motion"

import IconRightArrow from "images/icons/right-arrow.inline.svg"

const Style = styled.div`
  .main {
    ${tw`flex flex-row items-center`}

    overflow: visible;
  }

  .frame {
    ${tw`flex flex-row items-center font-display`}
    border: 1px solid rgba(0,0,0,0);
    border-radius: 2px;
    padding: 3px;
    margin-left: 7px;
    position: relative;

    &.profile {
    }
  }

  .caption {
    ${tw`uppercase relative`}

    font-weight: 700;
    font-size: 13px;
    line-height: 1;
    opacity: 0;
    color: #ffffff;
    top: 1px;

    &.profile {
      font-size: 16.5px;
    }
  }

  .arrow {
    margin-left: 10px;

    svg {
      height: 18px;
      width: 18px;

      path {
        fill: rgba(255, 255, 255, 0.5);
      }

      &.active {
        height: 10px;
        width: 10px;

        path {
          fill: #a88124;
        }
      }
    }

    &.profile {
      path {
        fill: #a88124;
      }

      svg {
        height: 33px;
        width: 28px;

        &.active {
          height: 13px;
          width: 13px;
        }
      }
    }
  }
`

const BASE_FRAME_VARIANTS = {
  init: {
    left: -10,
    width: 30,
    backgroundColor: theme`colors.transparent`,
    borderColor: theme`colors.transparent`,
  },
  hover: {
    left: 0,
    width: 82,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    borderColor: theme`colors.yellow.light`,
    borderRadius: "1.88008px",
  },
}

const BASE_CAPTION_VARIANTS = {
  init: { opacity: 0, scale: 0.4, width: 0 },
  hover: { opacity: 1, scale: 1, width: 55 },
}

const BASE_ARROW_VARIANTS = {
  init: { height: 18, width: 18, marginBottom: 0 },
  hover: { height: 10, width: 10, marginBottom: 1 },
}

const frameVariants = {
  card: BASE_FRAME_VARIANTS,
  profile: {
    init: {
      ...BASE_FRAME_VARIANTS.init,
      width: 40,
    },
    hover: {
      ...BASE_FRAME_VARIANTS.hover,
      width: 75,
    },
  },
}

const captionVariants = {
  card: BASE_CAPTION_VARIANTS,
  profile: {
    init: BASE_CAPTION_VARIANTS.init,
    hover: {
      ...BASE_CAPTION_VARIANTS.hover,
      width: 45,
    },
  },
}

const arrowVariants = {
  card: BASE_ARROW_VARIANTS,
  profile: {
    init: {
      ...BASE_ARROW_VARIANTS.init,
      height: 33,
      width: 28,
    },
    hover: {
      ...BASE_ARROW_VARIANTS.hover,
      height: 13,
      width: 13,
    },
  },
}

const labels = {
  card: "Explore",
  profile: "More",
}

const ManualMoreLink = ({
  children,
  className = "",
  active,
  type = "card",
}) => {
  const controls = useAnimation()

  useEffect(() => {
    if (active) {
      controls.start("hover")
    } else {
      controls.start("init")
    }

    return controls.stop
  }, [active])

  return (
    <Style className={className}>
      <motion.div animate={controls} initial="init" className="main">
        <div className="pt-1">{children}</div>
        <motion.div variants={frameVariants[type]} className={`frame ${type}`}>
          <motion.div
            className={`caption ${type}`}
            variants={captionVariants[type]}
          >
            {labels[type]}
          </motion.div>
          <motion.div
            className={`arrow ${type}`}
            variants={arrowVariants[type]}
          >
            <IconRightArrow className={active ? "active" : ""} />
          </motion.div>
        </motion.div>
      </motion.div>
    </Style>
  )
}

export default ManualMoreLink
