import React from "react"
import { graphql } from "gatsby"
// eslint-disable-next-line no-unused-vars
import { PageFragment } from "components/fragments"
import tw, { styled } from "twin.macro"

import Layout from "components/layout"
import SEO from "components/seo"
import FeaturedTestimonial from "components/sections/testimonial"
import FeaturedVideo from "components/sections/featured-video"
import ArticleShowcase from "components/sections/article-showcase"

import IndustryProfiles from "components/industry-profiles-large"
import MonetizeDemandSection from "components/sections/monetize-demand"
import LightSlantedSection from "components/sections/light-slant"
import PartnerLogos from "components/partner-logos"
import IndustryBubbles from "components/sections/industry-bubbles"
import VerticalLine from "components/vertical-line"
import BasicBackgroundSection from "components/basic-background-section"
import BgIndustry from "images/bg/industry.jpg"
import BgApplications from "images/bg/applications.jpg"
import FeaturedPressCarousel from "components/featured-press-carousel"

import { getSharePreviewUrls } from "utils/share-preview"
const IndustriesBubblesContainer = styled.div`
  ${tw`pt-32 l:pt-44 m:pt-44 px-2 flex flex-col justify-around items-center content-center mx-auto max-w-s m:max-w-m l:max-w-l min-h-screen`}

  @media (min-width: 1200px) {
    padding-top: 10.375rem;
  }
`
const Industry = ({ data }) => {
  const { page } = data.gcms

  return (
    <Layout>
      {!!page && (
        <SEO
          title={page.title}
          description={page.description}
          path={page.path}
          sharePreviewUrls={getSharePreviewUrls(page)}
        />
      )}
      <BasicBackgroundSection
        image={BgIndustry}
        color="#000000"
        bgPosition="center"
        size="cover"
        repeat="no-repeat"
      >
        <div
          style={{
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0) 90%, rgb(0, 0, 0) 100%) repeat scroll 0% 0%",
          }}
        >
          <IndustriesBubblesContainer>
            <IndustryBubbles />
          </IndustriesBubblesContainer>
        </div>
      </BasicBackgroundSection>
      <BasicBackgroundSection
        image={BgApplications}
        color="#000000"
        repeat="repeat-y"
        size="100% auto"
        bgPosition="center"
        position="relative"
        className="pb-32"
      >
        <div
          style={{
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0) 95%, rgb(0, 0, 0) 100%) repeat scroll 0% 0%",
          }}
        >
          <div className="px-2 flex flex-col justify-center items-center content-center mx-auto max-w-s m:max-w-m l:max-w-l">
            <VerticalLine />
            <IndustryProfiles />
          </div>
        </div>
        {!!page && !!page.featuredTestimonial && (
          <FeaturedTestimonial testimonial={page.featuredTestimonial} />
        )}
        {!!page && !!page.featuredVideo && (
          <FeaturedVideo video={page.featuredVideo} />
        )}
        {!!page && !!page.featuredLogosClients && (
          <div className="py-16 mx-auto max-w-s m:max-w-m l:max-w-l">
            <PartnerLogos data={page.featuredLogosClients} />
          </div>
        )}
      </BasicBackgroundSection>
      <LightSlantedSection>
        {page?.articleShowcase?.length > 0 && (
          <div>
            <ArticleShowcase articles={page.articleShowcase} />
          </div>
        )}
        {page?.featuredPressArticles?.length > 0 && (
          <div className="mb-24">
            <FeaturedPressCarousel articles={page.featuredPressArticles} />
          </div>
        )}
      </LightSlantedSection>

      <MonetizeDemandSection />
    </Layout>
  )
}

export default Industry

export const query = graphql`
  query {
    gcms {
      page(where: { path: "/industry" }) {
        ...PageData
      }
    }
  }
`
