import React, { useState } from "react"
import { navigate } from "@reach/router"
import tw, { styled } from "twin.macro"
import { motion } from "framer-motion"

const Styles = styled.div`
  ${tw`inline-block relative overflow-visible`}
  --button-color: #05c56b;

  button {
    ${tw`rounded uppercase font-display text-white relative w-full`}
    font-size: 18px;
    line-height: 14px;
    padding: 1.25rem;
    z-index: 2;
    -webkit-appearance: none;
    appearance: none;
    transition: all 100ms;

    /* default color is green */
    background: linear-gradient(180deg, #05c56b 0%, #00933e 100%);
    &:hover {
      background: linear-gradient(0deg, #05c56b 0%, #00933e 100%);
    }

    &:active {
      transform: scale(0.9);
      background-color: rgba(0, 0, 0, 0.2);
      background: linear-gradient(180deg, #05c56bcc 0%, #00933ecc 100%);
    }

    &:focus {
      outline: 0;
    }
  }

  &.small {
    button {
      font-size: 14px;
      line-height: 12px;
      padding: 1rem;
    }
  }

  &.flat {
    button {
      background: #333333;
    }
    --button-color: #333333;
  }

  &.blue {
    --button-color: #3498db;
    button {
      background: linear-gradient(180deg, #3498db 0%, #006aa9 100%);

      &:hover {
        background: linear-gradient(0deg, #3498db 0%, #006aa9 100%);
      }
    }

    &.glow {
      button {
        box-shadow: 0 0 4px 0 rgba(0, 106, 169, 0.51);
      }
    }
  }

  &.purple {
    --button-color: #7c43c9;
    button {
      background: linear-gradient(180deg, #7c43c9 0%, #481397 100%);

      &:hover {
        background: linear-gradient(0deg, #7c43c9 0%, #481397 100%);
      }
    }

    &.glow {
      button {
        box-shadow: 4px 4px 15px 0 rgba(124, 67, 201, 0.5);
      }
    }
  }

  &.red {
    --button-color: #e3284c;
    button {
      background: linear-gradient(180deg, #e3284c 0%, #aa0024 100%);

      &:hover {
        background: linear-gradient(0deg, #e3284c 0%, #aa0024 100%);
      }
    }

    &.glow {
      button {
        box-shadow: 4px 4px 15px 0 rgba(227, 40, 76, 0.5);
      }
    }
  }

  &.yellow {
    --button-color: #c69724;
    button {
      background: linear-gradient(180deg, #c69724 0.06%, #876718 100%);

      &:hover {
        background: linear-gradient(0deg, #c69724 0.06%, #876718 100%);
      }
    }
  }

  &.transparent {
    --button-color: rgba(0, 0, 0, 0);
    button {
      background: rgba(0, 0, 0, 0);
    }

    &:hover {
      color: #e6e6e6;
    }
  }

  &.semi-transparent {
    --button-color: rgba(0, 0, 0, 0.3);
    button {
      background: rgba(0, 0, 0, 0.4);
    }

    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.1),
      0px 2px 2px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1),
      0px 8px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    &:hover {
      color: #e6e6e6;
    }
  }

  .bubble {
    border-radius: 999px;
    border: 1px solid var(--button-color);
    position: absolute;
    opacity: 0;

    &.filled {
      background-color: var(--button-color);
    }
  }

  &.secondary {
    button {
      ${tw`font-bold text-springgreen-secondary`}

      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.14) 0%,
        rgba(255, 255, 255, 0.1) 100%
      );
      backdrop-filter: blur(2px);
      border-radius: 5px;

      &:hover {
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.14) 0%,
          rgba(255, 255, 255, 0.1) 100%
        );
      }
    }

    &.white-text {
      button {
        ${tw`text-white`}
      }
    }

    &.red-text {
      button {
        ${tw`text-neonred-primary`}
      }
    }

    &.monitor {
      height: 54px;

      button {
        padding: 1.0rem 1.25rem;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1),
          0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.1),
          0px 4px 4px rgba(0, 0, 0, 0.1), 0px 8px 8px rgba(0, 0, 0, 0.1);
      }
    }
  }
`

const Button = ({ children, onClick, to, className, ...rest }) => {
  const [active, setActive] = useState(false)
  const internal = /^\/(?!\/)/.test(to)
  const scroll = /^#/.test(to)
  function click() {
    if (!active) {
      setActive(true)
      setTimeout(function () {
        setActive(false)
      }, 500)
    }
    if (onClick) {
      onClick()
    } else {
      if (to) {
        if (internal) {
          navigate(to)
        } else if (scroll) {
          document.querySelector(to).scrollIntoView({
            behavior: "smooth",
          })
        } else {
          window.location.href = to
        }
      }
    }
  }
  const bubbles = Array.from({ length: 15 }, (v, i) => i)
  const transition = { duration: 0.5, ease: "easeInOut" }

  function rand(from, to) {
    return Math.round(Math.random() * (to - from) + from)
  }

  return (
    <Styles className={className}>
      {bubbles.map((b, idx) => {
        const diameter = rand(5, 10)
        return (
          <motion.div
            className={`bubble ${idx % 2 === 0 ? "filled" : ""}`}
            key={idx}
            style={{
              height: diameter,
              width: diameter,
            }}
            animate={active ? "animate" : "static"}
            transition={transition}
            variants={{
              static: {
                left: `${b * (90 / bubbles.length) + rand(-2, 2) + 5}%`,
                top: "10%",
                scale: 1,
                opacity: 0,
              },
              animate: {
                left: `${b * (150 / bubbles.length) + rand(-2, 2) - 25}%`,
                top: `${rand(-50, 20)}%`,
                scale: [1, 0.8, 0],
                opacity: [1, 1, 1, 1, 0],
              },
            }}
          />
        )
      })}
      <button onClick={click} {...rest} className={active ? "animate" : ""}>
        {children}
      </button>
      {bubbles.map((b, idx) => {
        const diameter = rand(5, 10)
        return (
          <motion.div
            className={`bubble ${idx % 2 === 0 ? "filled" : ""}`}
            key={idx}
            style={{
              height: diameter,
              width: diameter,
            }}
            animate={active ? "animate" : "static"}
            transition={transition}
            variants={{
              static: {
                left: `${b * (90 / bubbles.length) + rand(-2, 2) + 5}%`,
                top: "75%",
                scale: 1,
                opacity: 0,
              },
              animate: {
                left: `${b * (150 / bubbles.length) + rand(-2, 2) - 25}%`,
                top: `${rand(50, 135)}%`,
                scale: [1, 0.8, 0],
                opacity: [1, 1, 1, 1, 0],
              },
            }}
          />
        )
      })}
    </Styles>
  )
}

export default Button
