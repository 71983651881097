import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useMediaQuery } from "react-responsive"
import Industry from "components/industry"
import Divider from "images/icons/industry-divider-hor.inline.svg"

const Industries = () => {
  const isMobile = useMediaQuery({ maxWidth: 1200 })

  const data = useStaticQuery(graphql`
    query INDUSTRIES_COMP {
      gcms {
        industriesConnection(orderBy: order_ASC) {
          edges {
            node {
              id
              title
              slug
              timelineImage {
                url
              }
            }
          }
        }
      }
    }
  `)
  const industries = data.gcms.industriesConnection.edges

  return (
    <div className="flex flex-col l:flex-0 justify-center relative">
      {isMobile && (
        <>
          {industries?.map(({ node: industry }) => (
            <Industry
              cover={industry.timelineImage.url}
              key={industry.id}
              label={industry.title}
              link={`/industry/${industry.slug}`}
            />
          ))}
        </>
      )}
      {!isMobile && (
        <>
          <div className="flex flex-row justify-center relative">
            {industries?.map(
              ({ node: industry }, idx) =>
                idx % 2 === 1 && (
                  <div key={idx}>
                    <Industry
                      cover={industry.timelineImage.url}
                      key={idx}
                      label={industry.title}
                      link={`/industry/${industry.slug}`}
                      point="down"
                    />
                  </div>
                )
            )}
          </div>
          <div className="w-full overflow-hidden">
            <Divider />
          </div>
          <div className="flex flex-row justify-center">
            {industries?.map(
              ({ node: industry }, idx) =>
                idx % 2 === 0 && (
                  <div key={idx}>
                    <Industry
                      cover={industry.timelineImage.url}
                      key={idx}
                      label={industry.title}
                      link={`/industry/${industry.slug}`}
                      point="up"
                    />
                  </div>
                )
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default Industries
