import React from "react"
import tw, { styled } from "twin.macro"

import QuoteMarkSVG from "images/icons/quote.inline.svg"

const OpenQuote = styled.div`
  width: 70px;
  height: 42px;
  position: absolute;
  top: 27px;
  left: 0;
  margin-bottom: -20px;
`

const CloseQuote = styled.div`
  transform: scaleX(-1) scaleY(-1);
  width: 70px;
  height: 42px;
  position: absolute;
  bottom: 52px;
  right: 0;
  margin-bottom: -20px;
`

const Quote = styled.div`
  ${tw`font-italic italic text-center text-white py-12`}
  font-size: 15px;
  line-height: 29px;
  @media (min-width: 640px) {
    font-size: 22px;
    line-height: 41px;
  }
`

const Author = styled.div`
  ${tw`font-display text-center text-yellow`}
  font-size: 24px;
  line-height: 30px;
`

const Position = styled.div`
  ${tw`font-body text-center text-white`}
  font-size: 17px;
  line-height: 30px;
`

const Logo = styled.div`
  ${tw`my-4`}
  height: 60px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`

export default ({ testimonial }) => {
  return (
    <div className="px-2 py-16 mx-auto max-w-s m:max-w-m l:max-w-l">
      <div className="l:10/12 mx-auto flex flex-col items-center justify-center overflow-visible relative">
        <div className="relative">
          <OpenQuote>
            <QuoteMarkSVG />
          </OpenQuote>
          <Quote
            dangerouslySetInnerHTML={{
              __html: testimonial.quote.html,
            }}
          />
          <CloseQuote>
            <QuoteMarkSVG />
          </CloseQuote>
        </div>

        <Author>{testimonial.authorName}</Author>
        <Position>{testimonial.authorPosition}</Position>
        {testimonial.logo && (
          <Logo>
            <img src={testimonial.logo.url} alt="author logo" />
          </Logo>
        )}
      </div>
    </div>
  )
}
