import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import StyledBlock from "components/newsroom/styled-block"
import Industries from "components/industries"

const Paragraph = styled.div`
  ${tw`font-body text-white text-center m:text-left pt-6 pb-16 l:px-0 m:px-16`}
`

const IndustryBubbles = () => {
  const data = useStaticQuery(graphql`
    {
      gcms {
        contentBlocks(where: { identifier: "industry-bubbles" }, first: 1) {
          title
          headerContent {
            html
          }
        }
      }
    }
  `)
  const industryBubbles = data.gcms.contentBlocks[0]

  return (
    <>
      <Paragraph>
        <StyledBlock
          className="page-header"
          dangerouslySetInnerHTML={{
            __html: `<h1>${industryBubbles.title}</h1>${industryBubbles?.headerContent?.html}`,
          }}
        />
      </Paragraph>
      <Industries />
    </>
  )
}

export default IndustryBubbles
